export var CONSTANTS;
(function (CONSTANTS) {
})(CONSTANTS || (CONSTANTS = {}));
export var DEVICE;
(function (DEVICE) {
    DEVICE["MOBILE"] = "mobile";
    DEVICE["TABLET"] = "tablet";
    DEVICE["DESKTOP"] = "desktop";
})(DEVICE || (DEVICE = {}));
export var PLATFORM;
(function (PLATFORM) {
    PLATFORM["WIN"] = "windows";
    PLATFORM["MAC"] = "mac";
    PLATFORM["MACIntel"] = "mac_intel";
    PLATFORM["AND"] = "android";
    PLATFORM["INDUS"] = "indus";
    PLATFORM["IOS"] = "iOS";
    PLATFORM["LINUX"] = "linux";
    PLATFORM["LINUX18"] = "linux_18";
    PLATFORM["LINUX22"] = "linux_22";
    PLATFORM["WIN_PORATBLE"] = "windows_portable";
})(PLATFORM || (PLATFORM = {}));
export var THEME;
(function (THEME) {
    THEME["LIGHT"] = "light";
    THEME["DARK"] = "dark";
})(THEME || (THEME = {}));
