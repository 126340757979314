import { useState } from 'react';
import { useOsStore } from '../../../StateManager/OsStore';
import { useThemeStore } from '../../../StateManager/ThemeStore';
import { THEME } from '../../constants/constants';
var useNavbarHook = function () {
    var _a = useThemeStore(), theme = _a.theme, setTheme = _a.setTheme;
    var _b = useOsStore(), os = _b.os, setOs = _b.setOs;
    var _c = useState(false), isSideMenu = _c[0], setIsSideMenu = _c[1];
    function handleThemetoggle() {
        if (theme == THEME.LIGHT) {
            setTheme(THEME.DARK);
            localStorage.setItem("Theme", THEME.DARK);
        }
        else {
            setTheme(THEME.LIGHT);
            localStorage.setItem("Theme", THEME.LIGHT);
        }
    }
    function handleHamClick() {
        if (isSideMenu) {
            setIsSideMenu(false);
        }
        else {
            setIsSideMenu(true);
        }
        // setIsSideMenu(!isSideMenu)
    }
    function closeSideMenu() {
        setIsSideMenu(!isSideMenu);
    }
    return {
        theme: theme,
        setTheme: setTheme,
        os: os,
        setOs: setOs,
        handleThemetoggle: handleThemetoggle,
        isSideMenu: isSideMenu,
        setIsSideMenu: setIsSideMenu,
        handleHamClick: handleHamClick,
        closeSideMenu: closeSideMenu,
    };
};
export default useNavbarHook;
