import { useEffect, useState } from 'react';
import { useDeviceStore } from './StateManager/DeviceStore';
import { useOsStore } from './StateManager/OsStore';
import { useThemeStore } from './StateManager/ThemeStore';
import { DEVICE, PLATFORM, THEME } from './_shared/constants/constants';
var useAppHook = function () {
    var _a = useState(true), isButtonVisible = _a[0], setIsButtonVisible = _a[1];
    var _b = useThemeStore(), theme = _b.theme, setTheme = _b.setTheme;
    // const { localization, setLocalization } = useLocalizationStore()
    var _c = useOsStore(), os = _c.os, setOs = _c.setOs;
    var _d = useDeviceStore(), device = _d.device, setDevice = _d.setDevice;
    function handleResize() {
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE);
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP);
        }
        else {
            setDevice(DEVICE.TABLET);
        }
    }
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE);
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP);
        }
        else {
            setDevice(DEVICE.TABLET);
        }
        if (!localStorage.getItem("Theme")) {
            setTheme(THEME.LIGHT);
            localStorage.setItem("Theme", THEME.LIGHT);
        }
        else {
            // setTheme(localStorage.getItem("Theme") as string);
            setTheme(THEME.LIGHT);
            localStorage.setItem("Theme", THEME.LIGHT);
        }
        getOS();
    }, []);
    var getOS = function () {
        var userAgent = window.navigator.userAgent;
        var platform = window.navigator.platform;
        var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        var macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'];
        var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        var androidPlatforms = ['Android'];
        // setOs(window.navigator.userAgent)
        if (windowsPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.WIN);
            return 'Windows';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width > 1400) {
            setOs(PLATFORM.MAC);
            return 'mac';
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.IOS);
            return 'iOS';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width < 1200) {
            setOs(PLATFORM.IOS);
            return 'iPadOS';
        }
        else if (userAgent.indexOf('Android') !== -1) {
            setOs(PLATFORM.AND);
            return 'android';
        }
        else if (/Linux/.test(userAgent)) {
            setOs("linux");
            return 'Linux';
        }
        setOs(PLATFORM.WIN);
        return 'Unknown OS';
    };
    return {
        // fetchDataFromAPI,
        // localization, setLocalization,
        theme: theme,
        setTheme: setTheme,
        os: os,
        setOs: setOs,
        getOS: getOS,
        isButtonVisible: isButtonVisible,
        setIsButtonVisible: setIsButtonVisible,
    };
};
export default useAppHook;
