var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../_shared/constants/routes';
import * as styles from './notFound.module.scss';
function NotFound() {
    return (_jsxs("div", __assign({ className: styles.notFound }, { children: [_jsx("p", __assign({ className: styles.title }, { children: "OOPS! Page not Found" })), _jsxs("p", __assign({ className: styles.description }, { children: ["Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or visit our ", _jsx(Link, __assign({ to: ROUTES.HOME, reloadDocument: true }, { children: "Home Page" })), "."] })), _jsx(Link, __assign({ to: ROUTES.HOME, reloadDocument: true }, { children: _jsx("button", { children: "Go to Home" }) }))] })));
}
export default NotFound;
