// extracted by mini-css-extract-plugin
var _1 = "src-_shared-components-Footer-footer-component-module__copyright--dvdoF";
var _2 = "src-_shared-components-Footer-footer-component-module__footer--WbkPg";
var _3 = "src-_shared-components-Footer-footer-component-module__footerHeader--QQhYk";
var _4 = "src-_shared-components-Footer-footer-component-module__headerLogoContainer--gzeQn";
var _5 = "src-_shared-components-Footer-footer-component-module__horizontalLine--xi__s";
var _6 = "src-_shared-components-Footer-footer-component-module__keepPrivateLogo--FYcWJ";
var _7 = "src-_shared-components-Footer-footer-component-module__keepPrivateLogoContainer--KgQmg";
var _8 = "src-_shared-components-Footer-footer-component-module__linksAndMediaContainer--epr5j";
var _9 = "src-_shared-components-Footer-footer-component-module__linksChildContainer--n_0w4";
var _a = "src-_shared-components-Footer-footer-component-module__linksContainer--Tc8ut";
var _b = "src-_shared-components-Footer-footer-component-module__linksHeader--uyfrx";
var _c = "src-_shared-components-Footer-footer-component-module__linksSectionContainer--o11Gh";
var _d = "src-_shared-components-Footer-footer-component-module__mediaIconContainer--leZMQ";
var _e = "src-_shared-components-Footer-footer-component-module__mediaIcons--aH74t";
var _f = "src-_shared-components-Footer-footer-component-module__privacyMediaContainer--opOnU";
var _10 = "src-_shared-components-Footer-footer-component-module__zirohLogo--TYY5L";
export { _1 as "copyright", _2 as "footer", _3 as "footerHeader", _4 as "headerLogoContainer", _5 as "horizontalLine", _6 as "keepPrivateLogo", _7 as "keepPrivateLogoContainer", _8 as "linksAndMediaContainer", _9 as "linksChildContainer", _a as "linksContainer", _b as "linksHeader", _c as "linksSectionContainer", _d as "mediaIconContainer", _e as "mediaIcons", _f as "privacyMediaContainer", _10 as "zirohLogo" }
