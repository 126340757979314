var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './App.scss';
import Routing from "./App.routing";
import useAppHook from "./App.hook";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDeviceStore } from "./StateManager/DeviceStore";
import { DEVICE } from "./_shared/constants/constants";
function App() {
    var theme = useAppHook().theme;
    var device = useDeviceStore().device;
    return (_jsxs("div", __assign({ className: "App ".concat(theme) }, { children: [_jsx(Routing, {}), _jsx(ToastContainer, { position: device === DEVICE.MOBILE ? "bottom-center" : 'bottom-left', autoClose: 5000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true, 
                // @ts-ignore
                theme: theme })] })));
}
export default App;
