import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, RouterProvider, createHashRouter } from 'react-router-dom';
import Layout from './Layout/layout';
import Home from './Pages/Home/home';
import NotFound from './Pages/NotFound/notFound';
import { ROUTES } from './_shared/constants/routes';
var Routing = function (props) {
    var AppRouter = window.process ? createHashRouter : createBrowserRouter;
    var routes = AppRouter([
        {
            path: "/",
            element: _jsx(Layout, { children: " " }),
            children: [
                {
                    path: ROUTES.HOME,
                    element: _jsx(Home, {})
                },
                // {
                // 	path: ROUTES.ABOUT_US,
                // 	element: <AboutUs />
                // },
                {
                    path: "/*",
                    element: _jsx(NotFound, {})
                }
            ]
        }
    ]);
    return _jsx(RouterProvider, { router: routes });
};
export default Routing;
