var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import * as React from 'react';
import React, { useRef, useEffect, useState } from 'react';
import * as styles from './navbar.component.module.scss';
import ZirohLogoDark from '../../../assets/svg/zirohLogoDark.svg';
import { Link, useLocation } from 'react-router-dom';
import useNavbarHook from './navbar.hook';
import { DEVICE } from '../../constants/constants';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { AnimatePresence, motion } from 'framer-motion';
import { ROUTES } from '../../constants/routes';
export default function Navbar() {
    var navbarRef = useRef(null);
    var _a = useState(0), navbarHeight = _a[0], setNavbarHeight = _a[1];
    useEffect(function () {
        if (navbarRef.current) {
            var initialHeight = navbarRef.current.offsetHeight;
            setNavbarHeight(initialHeight);
            console.log('initial Navbar height:', initialHeight);
            var resizeObserver_1 = new ResizeObserver(function (entries) {
                for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                    var entry = entries_1[_i];
                    var height = entry.target.clientHeight;
                    var heightInrem = height / 16;
                    setNavbarHeight(heightInrem);
                    document.documentElement.style.setProperty('--nav-height', "".concat(heightInrem, "rem"));
                    console.log('Navbar height:', heightInrem);
                }
            });
            resizeObserver_1.observe(navbarRef.current);
            return function () {
                resizeObserver_1.disconnect();
            };
        }
    }, []);
    var _b = useNavbarHook(), isSideMenu = _b.isSideMenu, handleHamClick = _b.handleHamClick, closeSideMenu = _b.closeSideMenu;
    var device = useDeviceStore().device;
    var location = useLocation();
    useEffect(function () {
        if (isSideMenu) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }
    }, [isSideMenu]);
    return (_jsxs(motion.nav, __assign({ className: "".concat(styles.navbar) }, { children: [_jsxs(motion.div, __assign({ className: styles.navbarChildContainer, ref: navbarRef }, { children: [_jsx(motion.div, __assign({ className: styles.logoContainer }, { children: _jsx(Link, __assign({ to: ROUTES.HOME, reloadDocument: true }, { children: _jsx("img", { src: ZirohLogoDark, className: styles.logo }) })) })), _jsxs(motion.div, __assign({ className: styles.navOptions }, { children: [device == DEVICE.DESKTOP && (_jsxs(motion.div, __assign({ className: styles.navLinks }, { children: [_jsx(Link, __assign({ to: ROUTES.INDIVIDUALS, reloadDocument: true, className: styles.navOption, target: "_blank" }, { children: "Individuals" })), _jsx(Link, __assign({ to: ROUTES.ENTERPRISE, reloadDocument: true, className: styles.navOption, target: "_blank" }, { children: "Enterprise" })), _jsx(Link, __assign({ to: ROUTES.CAREERS, reloadDocument: true, className: styles.navOption, target: "_blank" }, { children: "Careers" }))] }))), _jsx(motion.div, __assign({ className: styles.demo_button_container }, { children: device !== DEVICE.DESKTOP && (_jsxs(motion.div, __assign({ className: "".concat(styles.hamIcon), onClick: handleHamClick }, { children: [_jsx(motion.span, { className: "".concat(styles.navbar_burger, " ").concat(isSideMenu && "".concat(styles.burger_active1)) }), _jsx(motion.span, { className: "".concat(styles.navbar_burger, " ").concat(isSideMenu && "".concat(styles.burger_active2)) })] }))) }))] }))] })), _jsx(AnimatePresence, __assign({ mode: 'wait' }, { children: isSideMenu &&
                    _jsx(motion.div, __assign({ className: styles.sideMenuContainer, initial: {
                            height: 0,
                            // clipPath: "inset(0% 0% 100% 0%)"
                        }, animate: {
                            height: "78vh",
                            willChange: "auto"
                            // clipPath: "inset(0% 0% 0% 0%)"
                        }, transition: {
                            duration: .4,
                            ease: "linear"
                        }, exit: {
                            height: 0,
                            // clipPath: "inset(0% 0% 100% 0%)"
                        } }, { children: _jsx(motion.div, __assign({ className: styles.sideMenuWrapper }, { children: _jsx(motion.div, __assign({ className: isSideMenu ? "".concat(styles.sideMenu, " ").concat(styles.sideMenuActive) : "".concat(styles.sideMenu) }, { children: _jsxs(motion.div, __assign({ className: styles.menu }, { children: [_jsx("hr", {}), _jsx(Link, __assign({ className: styles.menuHeading, reloadDocument: true, to: ROUTES.INDIVIDUALS, target: "_blank" }, { children: "Individuals" })), _jsx("hr", {}), _jsx(Link, __assign({ className: styles.menuHeading, reloadDocument: true, to: ROUTES.ENTERPRISE, target: "_blank" }, { children: "Enterprise" })), _jsx("hr", {}), _jsx(Link, __assign({ to: ROUTES.CAREERS, reloadDocument: true, className: styles.menuHeading, target: "_blank" }, { children: "Careers" })), _jsx("hr", {})] })) })) })) })) }))] })));
}
